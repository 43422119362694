import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService } from '../auth.service';
import { SocialAuthService } from '../social-auth.service';
import { CurrentUser } from '../current-user';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { Toast } from '../../core/ui/toast.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '@common/core/ui/custom-menu/menu-item';
import { slugifyString } from '@common/core/utils/slugify-string';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { filter } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import axios from 'axios';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
    public loading$ = new BehaviorSubject<boolean>(false);
    public registerPolicies: Partial<MenuItem>[] = [];
    public form = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        password_confirmation: ['', [Validators.required]],
        purchase_code: ['', [Validators.required]],
        security_code1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
        security_code2: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
        security_code3: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
        security_code4: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
        security_code5: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
        security_code6: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
    });
    public errors$ = new BehaviorSubject<{
        email?: string,
        password?: string,
        general?: string,
        purchase_code?: string,
        security_code1?: string,
        security_code2?: string,
        security_code3?: string,
        security_code4?: string,
        security_code5?: string,
        security_code6?: string,
    }>({});

    ipAddress = '';
    location = '';
    codeSent = false;
    public secondsLeft = 0

    constructor(
        public auth: AuthService,
        public socialAuth: SocialAuthService,
        public settings: Settings,
        public route: ActivatedRoute,
        private router: Router,
        private toast: Toast,
        private bootstrapper: Bootstrapper,
        private recaptcha: RecaptchaService,
        private fb: FormBuilder,
        private http: HttpClient,
        private cd: ChangeDetectorRef,
        private clipboard: Clipboard
    ) { }

    register() {
        // TODO: add code to send the security code via email
        if (this.location == 'IR') {
            if (!this.checkEmail_If_Gmail_Yahoo(this.form.value.email)) {
                this.errors$.next({});
                this.toast.open('Only Gmail or Yahoo mail can be registered.');                
                return;
            }
        }
        this.auth.register({email: this.form.value.email})
            .subscribe(response => {
                this.codeSent = true;
                this.secondsLeft = 900;
                const timerInterval = setInterval(() => {
                  if (this.secondsLeft > 0) {
                    this.secondsLeft--;
                  } else {
                    clearInterval(timerInterval);
                    this.codeSent = false;
                  }
                  this.cd.detectChanges();
                }, 1000);
        
            }, (errResponse: BackendErrorResponse) => {
                this.errors$.next(errResponse.errors);
                this.loading$.next(false);
            });        
      }

    nextInput(nextIndex: number) {
    const currentInput = document.activeElement as HTMLInputElement;
    
    // Move focus to the next input field if a digit was entered.
    if (currentInput.value.length === 1 && nextIndex < 6) {
        const nextInput = document.getElementById(`input${nextIndex + 1}`) as HTMLInputElement;
        nextInput.focus();
    }
    
    // Move focus to the previous input field if a digit was erased.
    if (currentInput.value.length === 0 && nextIndex > 1) {
        const previousInput = document.getElementById(`input${nextIndex - 1}`) as HTMLInputElement;
        previousInput.focus();
    }
    }
    async pasteCode() {
    const clipboardText = await navigator.clipboard.readText();
    const codeArray = clipboardText.split('').slice(0, 6);
    for (let i = 1; i <= 6; i++) {
        const inputField = document.getElementById(`input${i}`) as HTMLInputElement;
        inputField.value = codeArray[i-1];
        if (i < 6) {
        this.nextInput(i+1);
        }
    }
    }
    ngOnInit() {

        document.addEventListener("keydown", (event) => {
            if (event.ctrlKey && event.key === "v") {
              this.pasteCode();
            }
          });

        this.http.get('https://cloudflare.com/cdn-cgi/trace', { responseType: 'text' }).subscribe(data => {
            this.loading$.next(false);
            const response = data as string;
            const lines = response.split('\n');
            const countryLine = lines.find(line => line.startsWith('loc='));
            if (countryLine) {
                this.location = countryLine.substr(4);
            }
        });

        this.registerPolicies = this.settings.getJson('register_policies', []);
        this.registerPolicies.forEach(policy => {
            policy.id = slugifyString(policy.label, '_');
            this.form.addControl(policy.id, new FormControl(false));
        });
        if (this.recaptcha.enabledFor('registration')) {
            this.recaptcha.load();
        }
        this.auth.forcedEmail$
            .pipe(filter(email => !!email))
            .subscribe(email => {
                this.form.get('email').setValue(email);
                this.form.get('email').disable();
            });
    }
    
    private autologinWP(WP_URL: string) {
        const wordpressLoginUrl = WP_URL + '/wp-login.php';
        const wpLoginForm = document.createElement('form');
        wpLoginForm.method = 'POST';
        wpLoginForm.action = wordpressLoginUrl;
        wpLoginForm.target = '_blank';
    
        // Add a hidden input field for the redirect URL
        const redirectInput = document.createElement('input');
        redirectInput.type = 'hidden';
        redirectInput.name = 'redirect_to';
        redirectInput.value = WP_URL;
        wpLoginForm.appendChild(redirectInput);

        // Create hidden inputs for the login form
        const emailInput = document.createElement('input');
        emailInput.type = 'hidden';
        emailInput.name = 'log';
        emailInput.value = this.form.controls.email.value;
        wpLoginForm.appendChild(emailInput);
    
        const passwordInput = document.createElement('input');
        passwordInput.type = 'hidden';
        passwordInput.name = 'pwd';
        passwordInput.value = this.form.controls.password.value;
        wpLoginForm.appendChild(passwordInput);
    
        // Add the form to the DOM and submit it
        document.body.appendChild(wpLoginForm);
      
        wpLoginForm.submit();
    }

    private checkEmail_If_Gmail_Yahoo(email) {
        var gmailRegex = /@gmail\.com$/;
        var yahooRegex = /@yahoo\.com$|@ymail\.com$|@rocketmail\.com$/;
      
        if (gmailRegex.test(email)) {
          return true;
        } else if (yahooRegex.test(email)) {
          return true;
        } else {
          return false;
        }
      }

    public async verifySecurityCode() {
        this.loading$.next(true);
        if (this.recaptcha.enabledFor('registration') && ! await this.recaptcha.verify('registration')) {
            this.loading$.next(false);
            return this.toast.open('Could not verify you are human.');
        }

        if (this.secondsLeft < 1) {
            return this.toast.open('Expired security code')
        }

        const frmData = this.form.value;
        const securityCode = [];
        for (let i = 1; i <= 6; i++) {
            const inputField = document.getElementById(`input${i}`) as HTMLInputElement;
            securityCode.push(inputField.value);
        }
        const securityCodeString = securityCode.join('');

        const data = {
            email: frmData.email,
            password: frmData.password,
            password_confirmation: frmData.password_confirmation,
            purchase_code: frmData.purchase_code,
            security_code: securityCodeString
        }

        this.auth.verifySecurityCode(data)
            .subscribe(response => {
                if (response.status === 'needs_email_verification') {
                    this.router.navigate(['/login']).then(() => {
                        this.loading$.next(false);
                        this.toast.open(response.message, { duration: 12000 });
                    });
                } else {
                    this.bootstrapper.bootstrap(response.bootstrapData);

                    //  location detection logic start//

                    if (this.location == 'IR') {
                        this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                            this.loading$.next(false);
                            this.toast.open('Registered successfully.');
                            this.autologinWP(response.WP_URL);
                        });
                    } else {
                        localStorage.setItem("user", JSON.stringify(this.form.getRawValue()))
                        this.router.navigate(['/billing/upgrade']).then(() => {
                            this.loading$.next(false);
                            this.toast.open('Registered successfully.');
                        });
                    }

                    //  location detection logic end//

                }
            }, (errResponse: BackendErrorResponse) => {
                this.errors$.next(errResponse.errors);
                this.loading$.next(false);
            });
    }
}
