<auth-page infoRowTarget="signin">
  <form
    (ngSubmit)="verifySecurityCode()"
    *ngIf="errors$ | async as errors"
    [formGroup]="form"
    ngNativeValidate
  >
    <div class="header" [transValues]="{ siteName: settings.get('branding.site_name') }" trans>
      {{ route.snapshot.data.message || 'Create a new account' }}
    </div>

    <div class="many-inputs">
      <div class="input-container">
        <label for="email" trans>Email</label>
        <div class="digit-container">
          <input type="email" formControlName="email" id="email" required />
          <div>
            <button
              mat-raised-button
              class="mat-focus-indicator button primary update-button mat-raised-button mat-button-base mat-accent"
              color="accent"
              type="button"
              (click)="register()"
              style="margin-left: 5px"
              trans
              [disabled]="codeSent"
            >
              Send Code
            </button>
          </div>
        </div>
        <p class="error" *ngIf="errors.email">
          <mat-icon svgIcon="warning"></mat-icon>
          <span>{{ errors.email }}</span>
        </p>
      </div>

      <div class="input-container">
        <label for="security_code" trans>Security Code</label>
        <div class="digit-container">
          <input
            type="text"
            maxlength="1"
            (input)="nextInput(1)"
            formControlName="security_code1"
            id="input1"
            class="input-digit"
          />
          <input
            type="text"
            maxlength="1"
            (input)="nextInput(2)"
            formControlName="security_code2"
            id="input2"
            class="input-digit"
          />
          <input
            type="text"
            maxlength="1"
            (input)="nextInput(3)"
            formControlName="security_code3"
            id="input3"
            class="input-digit"
          />
          <input
            type="text"
            maxlength="1"
            (input)="nextInput(4)"
            formControlName="security_code4"
            id="input4"
            class="input-digit"
          />
          <input
            type="text"
            maxlength="1"
            (input)="nextInput(5)"
            formControlName="security_code5"
            id="input5"
            class="input-digit"
          />
          <input
            type="text"
            maxlength="1"
            (input)="nextInput(6)"
            formControlName="security_code6"
            id="input6"
            class="input-digit"
          />
        </div>
        <label for="security-code">Enter 6 digit security code: <br/>توجه داشته باشید هر آی پی هر 4 ساعت یک بار میتواند درخواست ارسال کند پس قبل ارسال به خوبی آدرس ایمیل خود را بررسی کنید</label><br />

        <p class="error" *ngIf="errors.security_code">
          <mat-icon svgIcon="warning"></mat-icon>
          <span>{{ errors.security_code }}</span>
        </p>
        <div *ngIf="codeSent">
          <p>Code sent! Enter the security code above.</p>
          <p>Time left: {{ secondsLeft }} seconds</p>
        </div>
      </div>

      <div class="input-container">
        <label for="register_password" trans>Password</label>
        <input type="password" formControlName="password" id="register_password" required />
        <p class="error" *ngIf="errors.password">
          <mat-icon svgIcon="warning"></mat-icon>
          <span>{{ errors.password }}</span>
        </p>
      </div>

      <div class="input-container">
        <label for="register_password_confirmation" trans>Confirm Password</label>
        <input
          type="password"
          formControlName="password_confirmation"
          id="register_password_confirmation"
          required
        />
      </div>

      <div
        class="input-container"
        *ngIf="settings.get('envato.enable') && settings.get('envato.require_purchase_code')"
      >
        <label for="purchase_code" trans>Envato Purchase Code</label>
        <input type="text" formControlName="purchase_code" id="purchase_code" required />
        <p class="error" *ngIf="errors.purchase_code">
          <mat-icon svgIcon="warning"></mat-icon>
          <span>{{ errors.purchase_code }}</span>
        </p>
      </div>
    </div>

    <div class="policies" *ngIf="registerPolicies.length">
      <mat-checkbox
        name="remember-me"
        class="policy-checkbox"
        *ngFor="let policy of registerPolicies"
        [formControlName]="policy.id"
        color="accent"
        required
        trans
      >
        <span trans>I accept the</span>&ngsp;
        <a
          [href]="
            policy.type === 'link' ? policy.action : settings.getBaseUrl() + '/' + policy.action
          "
          *ngIf="policy.action"
          target="_blank"
        >
          <strong>{{ policy.label | trans }}</strong>
        </a>
        <strong *ngIf="!policy.action">{{ policy.label | trans }}</strong>
      </mat-checkbox>
    </div>

    <button
      mat-raised-button
      class="primary-button"
      color="accent"
      type="submit"
      [disabled]="loading$ | async"
      trans
    >
      Create Account
    </button>

    <ng-container *ngIf="!settings.get('registration.disable')">
      <button
        type="button"
        class="no-style alternative-signin-method"
        (click)="socialAuth.loginWith('envato')"
        *ngIf="settings.get('social.envato.enable'); else defaultSocialAuth"
      >
        <mat-icon svgIcon="envato-custom"></mat-icon>
        <span trans>Sign in with Envato instead</span>
      </button>
      <ng-template #defaultSocialAuth>
        <div class="social-icons center">
          <button
            type="button"
            mat-icon-button
            (click)="socialAuth.loginWith('facebook')"
            *ngIf="settings.get('social.facebook.enable')"
            class="social-icon no-style facebook"
            [matTooltip]="'Login with facebook' | trans"
          >
            <mat-icon svgIcon="facebook"></mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            (click)="socialAuth.loginWith('google')"
            *ngIf="settings.get('social.google.enable')"
            class="social-icon no-style google"
            [matTooltip]="'Login with google' | trans"
          >
            <mat-icon svgIcon="google"></mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            (click)="socialAuth.loginWith('twitter')"
            *ngIf="settings.get('social.twitter.enable')"
            class="social-icon no-style twitter"
            [matTooltip]="'Login with twitter' | trans"
          >
            <mat-icon svgIcon="twitter"></mat-icon>
          </button>
        </div>
      </ng-template>
    </ng-container>
  </form>
</auth-page>
